import { Button, Drawer, Typography } from "antd";
import React, { useState } from "react";
import WhatsApp from "../../../Assets/Images/whatsapp.svg";
import menu from "../../../Assets/Images/mobile-menu.svg";
import { Link } from "react-router-dom";

const MobileNavbar = () => {
  const [open, setOpen] = useState(false);
  const { Text } = Typography;
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="width-66 d-flex justify-end">
      <a
        href="https://wa.me/+918595864155"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          type="primary"
          icon={<img src={WhatsApp} alt="whatsapp" />}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#068F2C",
          }}
          size="large"
          className="wa-mobile"
        />
      </a>
      <img src={menu} alt="menu" width="18px" onClick={showDrawer} />
      <Drawer onClose={onClose} open={open} width="200px">
        <div className="drawer-container">
          <Link to="/">
            {" "}
            <Text>Home</Text>
          </Link>

          <Text className="y-15">About us</Text>
          <Text className="b-15">Contact us</Text>
          <Link to="/privacy-policy">
            {" "}
            <Text>Privacy and policy</Text>
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNavbar;
