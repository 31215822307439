import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Col, Row } from "antd";
import { travellerSayings } from "../../../../Utils/staticData";
import TravellerCard from "./TravellerCard";

const TravelerSay = () => {
  return (
    <div className="traveller-say-container">
      <div className="travellers-header">
        <Title level={1} className="travellers-banner-text reset-margin">
          <span className="black-text">What our</span>{" "}
          <span className="primary-text">Travelers</span>
          <span className="black-text"> say about us</span>
        </Title>

        <Paragraph className="grey-text traveller-description paragraph popular-description">
          Here are some delightful expressions shared by our travelers who had a
          wonderful experience with Mount Monks.
        </Paragraph>
      </div>
      <Row gutter={[15, 15]}>
        {travellerSayings.map((saying) => (
          <Col md={8}>
            {" "}
            <TravellerCard {...saying} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TravelerSay;
