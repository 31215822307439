import React from "react";
import "./index.css";
import Banner from "./Components/Banner";
import PopularPackage from "./Components/PopularPackage";
import Recommendations from "./Components/Recommendations";
import Explore from "./Components/Explore";
import WhyChooseUs from "./Components/WhyChooseUs";
import OurStats from "./Components/OurStats";
import TravelerSay from "./Components/TravelerSay";
import GetInTouch from "./Components/GetInTouch";

const Home = () => {
  return (
    <div>
      <Banner />
      <PopularPackage />
      <Recommendations />
      <WhyChooseUs />
      <OurStats />
      <Explore />
      <TravelerSay />
      <GetInTouch />
    </div>
  );
};

export default Home;
