import React from "react";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import "./index.scss";
import stat from "../../../../Assets/Images/ourstats.png";
import crossDesign from "../../../../Assets/Images/crossDesign.svg";
import check from "../../../../Assets/Images/greencheck.svg";
import { ourStats, ourStatsNumbers } from "../../../../Utils/staticData";
import { Divider } from "antd";

const StatLine = ({ description }) => (
  <div className="stat-line">
    <img src={check} width="16px" alt="check" />
    <Title level={5} className="title-margin-custom">
      {description}
    </Title>
  </div>
);

const StatNumber = ({ number, label }) => (
  <div className="number-container">
    <Title level={1} className="primary-text stat-number">
      {number}
    </Title>
    <Paragraph className="grey-text stats-numer-description paragraph">
      {label}
    </Paragraph>
  </div>
);
const OurStats = () => {
  return (
    <div className="stats-container">
      <img
        src={crossDesign}
        height="77px"
        alt="cross-design"
        className="stat-design-1"
      />
      <img src={stat} alt="stat" className="stat-image" />
      <div className="stat-data">
        <Title level={1} className="stats-banner-text">
          <span className="black-text">Our</span>{" "}
          <span className="primary-text">Stats</span>
        </Title>
        <Paragraph className="grey-text stats-description paragraph">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s.
        </Paragraph>
        {ourStats.map((stat) => (
          <StatLine description={stat} />
        ))}
        <div className="stat-figures-container d-flex align-center">
          {ourStatsNumbers.map(({ label, number }, index) => (
            <>
              <StatNumber label={label} number={number} />
              {index < ourStatsNumbers.length - 1 && (
                <Divider type="vertical" className="stat-divider" />
              )}
            </>
          ))}
        </div>
      </div>
      <img
        src={crossDesign}
        height="77px"
        alt="cross-design"
        className="stat-design-2"
      />
    </div>
  );
};

export default OurStats;
