import { Card, Grid } from "antd";
import React from "react";
import "./index.css";
import logo from "../../../Assets/Images/logo.svg";
import MobileNavbar from "./MobileNavbar";
import DeskNavBar from "./DeskNavBar";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  return (
    <div>
      <Card
        className="navbar-container"
        style={{ height: "80px" }}
        styles={{
          body: {
            height: "80px",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "0px",
            paddingRight: "0px",
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <div className="width-33">
          {" "}
          <Link to="/">
            {" "}
            <img src={logo} width="51px" alt="logo" />
          </Link>
        </div>
        {xs ? <MobileNavbar /> : <DeskNavBar />}
      </Card>
    </div>
  );
};

export default NavBar;
