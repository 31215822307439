import { Button, Col, Row, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import React from "react";
import crossDesign from "../../../../Assets/Images/crossDesign.svg";
import vietnam from "../../../../Assets/Images/vietnam.png";

import "./index.scss";
import RecommendationsCard from "./Card";
import { recommendations } from "../../../../Utils/staticData";

const Recommendations = () => {
  const { Title } = Typography;
  return (
    <div className="recommendations-container">
      <div className="recommendations-header">
        <div className="recommendations-design-container">
          {" "}
          <img
            src={crossDesign}
            height="77px"
            alt="cross-design"
            className="recommendation-design"
          />
        </div>

        <div className="center-title recommendations-title recommendations-title-container">
          <Title level={1} className="recommendations-banner-text reset-margin">
            <span className="black-text">Travel</span>{" "}
            <span className="primary-text">Recommendations</span>
          </Title>
          <Title
            level={3}
            className="grey-text recommendations-description popular-description"
          >
            The best travel recommendation from around the world for you
          </Title>
        </div>
      </div>
      <div className="recommendations-body">
        <Row gutter={[24, 30]}>
          {recommendations.map((recommendationData) => (
            <Col xs={24} md={8}>
              <RecommendationsCard recommendationData={recommendationData} />
            </Col>
          ))}
        </Row>
      </div>
      <div className="recommendation-footer d-flex justify-center y-15">
        {/* <Button
          className="contained-primary"
          size="large"
          style={{ width: "258px" }}
        >
          Explore More
          <RightOutlined />
        </Button> */}
      </div>
    </div>
  );
};

export default Recommendations;
