import React from "react";
import "./index.scss";
import logo from "../../../Assets/Images/logo.svg";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import up from "../../../Assets/Images/go_up.svg";
import { scrollToTop } from "../../../Utils/helpers";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="address-container">
        <img src={logo} width="87px" alt="logo" />
        <Title level={4}>
          Mountmonks Adventures Pvt Ltd 307 Kirti Mahal, Rajendra Place Delhi:
          110008
        </Title>
        <Title
          level={5}
          className="address-highliter"
          style={{ width: "120px" }}
        >
          (434) 546-4356
        </Title>
        <Title
          level={5}
          className="address-highliter"
          style={{ width: "200px" }}
        >
          contact@mountmonk.com
        </Title>
      </div>
      <div className="footer-link-copyright-wrapper">
        <div className="footer-links-container">
          <div className="footer-page-links">
            <Paragraph>About</Paragraph>
            <Paragraph>All Bookings</Paragraph>
            <Paragraph>Place to Explore</Paragraph>
            <Paragraph>Partners</Paragraph>
            <Paragraph>Contact</Paragraph>
          </div>
          <div className="footer-social-links">
            <Paragraph>Facebook</Paragraph>
            <Paragraph>Twitter</Paragraph>
            <Paragraph>Linkedin</Paragraph>
            <Paragraph>Instagram</Paragraph>
          </div>
        </div>
        <Paragraph className="copyright">
          © 2024 Mount Monks. All rights reserved.
        </Paragraph>
      </div>
      <img
        src={up}
        alt="up"
        className="go-up-arrow cursor"
        onClick={scrollToTop}
      />
    </div>
  );
};

export default Footer;
