import globe from "../Assets/Images/globe.svg";
import city from "../Assets/Images/city.svg";
import ticket from "../Assets/Images/ticket.svg";
import p1 from "../Assets/Images/people1.png";
import p2 from "../Assets/Images/people2.png";
import p3 from "../Assets/Images/people3.png";
import vietnam from "../Assets/Images/vietnam.png";
import bhutan from "../Assets/Images/bhutan.png";
import thailand from "../Assets/Images/thailand.png";
import vietnamR from "../Assets/Images/vietnam-recommend.png";
import bhutanR from "../Assets/Images/bhutan-recommend.png";
import thailandR from "../Assets/Images/thailand-recommend.png";
import image1 from "../Assets/Images/vietnam-1.JPG";
import image2 from "../Assets/Images/vietnam-2.JPG";
import image3 from "../Assets/Images/vietnam-3.JPG";
// import image4 from "../Assets/Images/vietnam-recommend.png";
import image5 from "../Assets/Images/vietnam-5.JPG";
import image6 from "../Assets/Images/vietnam-6.JPG";
import image7 from "../Assets/Images/vietnam-7.JPG";
import image8 from "../Assets/Images/vietnam-8.JPG";
import bhutan1 from "../Assets/Images/bhutan-1.JPG";
import bhutan2 from "../Assets/Images/bhutan-2.JPG";
import bhutan3 from "../Assets/Images/bhutan-3.JPG";
import bhutan4 from "../Assets/Images/bhutan-4.JPG";
import bhutan5 from "../Assets/Images/bhutan-5.JPG";
import thailand1 from "../Assets/Images/thailand-1.JPG";
import thailand3 from "../Assets/Images/thailand-3.JPG";
import thailand4 from "../Assets/Images/thailand-4.JPG";
import thailand5 from "../Assets/Images/thailand-5.JPG";
import thailand6 from "../Assets/Images/thailand-6.JPG";
import thailand7 from "../Assets/Images/thailand-7.JPG";
import thailand8 from "../Assets/Images/thailand-8.JPG";
import vietnamPackage from "../Assets/Documents/vietnam-package.pdf";
import bhutanPackage from "../Assets/Documents/bhutan-package.pdf";
import thailandPackage from "../Assets/Documents/thailand-package.pdf";

export const whyChoseCards = [
  {
    id: 1,
    title: "Lot of choices",
    icon: globe,
    description: "Total 500+ destination that we offer you to travel.",
  },
  {
    id: 2,
    title: "Best Tour Guide",
    icon: city,
    description: "Our Tour Guide with 10+ years of experience",
  },
  {
    id: 3,
    title: "Easy Booking",
    icon: ticket,
    description: "With an fast and easy ticket purchase process",
  },
];

export const ourStats = [
  "Zen Retreat Package",
  "Adventure Explorer Expedition",
  "Culinary Bliss Experience",
  "Spiritual Wellness Retreats",
];

export const ourStatsNumbers = [
  {
    number: "50+",
    label: "Destinations",
  },
  {
    number: "200+",
    label: "Travelers",
  },
  {
    number: "50+",
    label: "Ratings",
  },
];

export const travellerSayings = [
  {
    id: 1,
    title: "A Tranquil Retreat",
    description:
      "My stay with Mount Monks was an absolute delight! The serene surroundings and warm hospitality made it a tranquil retreat. Highly recommended for those seeking peace and rejuvenation.",
    image: p1,
    name: "Sarah Johnson",
    rating: 4.5,
  },
  {
    id: 2,
    title: "Adventure Unleashed",
    description:
      "Mount Monks offered an exhilarating adventure! From thrilling treks to breathtaking vistas, every moment was a testament to nature's wonders. An unforgettable experience for the adventurous soul.",
    image: p2,
    name: "Sarah Johnson",
    rating: 5,
  },
  {
    id: 3,
    title: "Elevated Bliss",
    description:
      "Elevate your spirit with Mount Monks! The harmonious blend of spirituality and nature creates a unique haven. Grateful for the enriching experience and the soulful journey it provided.",
    image: p3,
    name: "Sarah Johnson",
    rating: 4,
  },
];

export const packageDetails = {
  vietnam: {
    id: 1,
    title: "Vietnam",
    name: "vietnam",
    image: vietnam,
    location: "Hanoi, Ho Chi Minh",
    startDate: "7 Night 8 Days",
    endDate: "",
    rating: "4.8",
    attachedFile: vietnamPackage,
    price: "49999",
    bannerImages: [image1, image2, image3, image5, image6, image7, image8],
    photosOfTheTrip: [image1, image2, image3, image5, image6, image7, image8],
    itenary: [
      {
        key: 1,
        label: "Day 1 : Ho Chi Minh arrival – Walking tour",
        children: (
          <ul>
            <li>
              The tour guide & driver will welcome you at the airport; it takes
              us 20 minutes to drive to the centre of HCM and then check in at
              the hotel.
            </li>
            <li>
              From 5 pm, take the walking tour to explore Saigon and immerse
              yourself in the city's heart between frenetic modernity and
              preserved traditions. Through an exclusive tour, the explorer will
              dive into a market frequented only by locals and observe the
              components of Saigonese life. Unique moment: watch the sunset with
              a panoramic view of the city and its surroundings from the highest
              tower in the city: Bitexco Tower ( ticket not included ).
            </li>
            <li>
              The charm will also operate at nightfall when all social classes
              gather on Nguyên Hue Avenue - the most beautiful avenue in the
              city - to relax with the family. End of our services at the end of
              the Walking Tour (at the HCMC Town Hall)
            </li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 2,
        label: `Day 2 : Ho Chi Minh city – Cu Chi Tunnel`,
        children: (
          <ul>
            <li>
              Depart for the Cu Chi tunnel, the trip will take approximately one
              and a half hours of car driving. Upon arrival, before exploring
              the tunnels, you will have a short introduction followed by an
              introductory video about how the tunnels were constructed and how
              the people survived in the harsh conditions of wartime.
            </li>
            <li>
              Then, spend your time exploring the remaining area and tunnel
              systems, including the specially constructed living areas with
              kitchens, and bedrooms side by side with other martial facilities
              like storage, weapons factories, field hospitals, and command
              centres helping whoever lives inside the tunnels meet their basic
              needs. Besides, there are also many hidden trap doors and
              dangerous traps within the maze-like tunnels for security purposes
              during the war. Afterwards, special tea and cassava (guerilla food
              during the war) will be served.
            </li>
            <li>
              Break time for relaxation or time for those who want to try the
              real shooting gun.
            </li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 3,
        label: `Day 3 : Ho Chi Minh – fly to Danang – Hoi An visit`,
        children: (
          <ul>
            <li>
              In the evening, enjoy the Basket boat ride and explore Hoi An
              ancient town, visiting the Japanese Pagoda Bridge, Chinese
              streets, old Phung Hung houses, Hoi An’s market and ancient port.
            </li>
            <li>
              On the 1st and 15th of every lunar month, Hoi An’s ancient town is
              lit up by hundreds of colourful lanterns, giving it an extra
              portion of sparkle.
            </li>
            <li>
              Enjoy the city’s transformation and stroll through this quaint,
              glowing fairytale town in your free time.
            </li>

            <li>Back to Danang.</li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 4,
        label: `Day 4 : Danang – Ba Na Hill`,
        children: (
          <ul>
            <li>
              07:45 - 8:00: Pick up from the hotel in Danang and start transfer
              to Ba Na Hills.
            </li>
            <li>
              Check-in at the Suoi Mo Cable Car Station and transfer to
              Marseille Station.
            </li>
            <li>
              Explore Golden Bridge (The Hands of The Gods) - majestic and
              impressive as a scene in “Game of Thrones” or “The Lords of the
              Rings”.
            </li>
            <li>
              Drop by Le Jardin D’Amour (the Flower Garden of Love) with 9
              gardens of Le Jardin D’Amour are 9 amusing tales in 9 varying
              architectures, which harmonize so well in a visual sonata of
              beauty.
            </li>
            <li>
              Continue the trip with Linh Ung Pagoda. Afternoon: Discover and
              play games at Fantasy Park - a perfect tourist attraction for an
              entire family as well as explore King Peak.
            </li>
            <li>
              Return to the Cable Car Station and back to the Gateway then
              transfer back to the hotel.
            </li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 5,
        label: `Day 5 : Danang – fly to Hanoi – Half day city tour`,
        children: (
          <ul>
            <li>
              After breakfast, transfer to the airport for the flight to Hanoi.
              The tour guide & driver will be welcoming you at Noi Bai airport;
              it takes us 35 minutes to drive to the centre of Hanoi and then
              check in at the hotel.
            </li>
            <li>
              Let’s start our city tour from the overall view of the capital
              with a walking tour around Hanoi's Old Quarter, stopping at Ngoc
              Son Temple located on the Hoan Kiem Lake (the “Sword” Lake). Visit
              the train street and grab famous egg coffee while enjoying the
              train-passing.
            </li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 6,
        label: `Day 6 : Hanoi – Ninh Binh – Trang An – Mua cave`,
        children: (
          <ul>
            <li>
              After breakfast, we pick you up at your hotel in Old Quarter, and
              depart for Hoa Lu-Tam Coc, enjoying the beautiful sceneries of the
              rural areas on the way.
            </li>
            <li>
              10h30: After arriving in Ninh Binh province, the guide will take
              you to Visit Mua Cave (Mua means Dance) with 500 steps up to the
              Top of Mua Moutain. On the Top, you can overview all the nice
              scenes of Hoa Lu. Have a lunch break
            </li>
            <li>
              14h00: Depart to visit Trang An Eco-Tourism attraction, there is a
              beautiful scenery landscape, you will board a small boat to visit
              Trang An, and you will see majestic limestone cliffs and
              mysterious yet inviting caves, as well as countryside sights, like
              peaceful streams, rice paddies and deserted green fields. Take a
              boat trip to visit the cave complex: Sang Cave, Toi Cave, Ba Giot
              Cave, and Nau Ruou in mystery and wildness, and see the scenery of
              mountains and rivers Ha Long on the land of Ninh Binh.
            </li>
            <li>17h00: Get in the car and go back to Ha Noi.</li>

            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 7,
        label: `Day 7 : Hanoi – Halong Bay cruise – Hanoi`,
        children: (
          <ul>
            <li>
              8h15-8h30: Our Tour guide will pick up you at the hotel in Ha Noi
              Old Quarter then depart to Halong Bay by Expressway Transfer.
            </li>
            <li>
              12h00 – 12h30: Arrive Tuan Chau Harbor. Welcome to Cozy Bay
              Premium Cruise. We will take you on a 5-6 hour journey via 2
              day-sleeper boat cruise route which is renowned for its striking,
              passages uninhabited islets and outcrops that have been given
              imaginative names by the locals, due to their perceived shapes
              while having lunch on the boat.
            </li>
            <li>
              14h00: You will visit Surprising Cave to see a lot of stalagmites
              and stalactites with different shapes of sea life.
            </li>
            <li>
              15h00: Visit Titop Island with a sandy beach to the towering
              limestone mountain with an excellent backdrop of the Bay, and
              climb up Titop Peak for a panoramic view of the bay-like paradise.
            </li>
            <li>
              16h00: Then do kayaking or on a bamboo boat in Luon Cave to
              discover the beautiful Lagoon.
            </li>
            <li>
              17h00 – 17h30: Cruising back to Tuan Chau harbour. There is a lot
              of time for enjoying sunset parties with tea, and cakes,
              sunbathing on the upper deck with music and enjoying great fun of
              group touring fellows among the world's iconic natural scenery.
            </li>
            <li>
              18h00 – 18h30: We get on the bus to back Hanoi by Expressway, and
              have a short break for 20-30 minutes.
            </li>
            <li>
              21h00 – 21h30: Arrive at the hotel in Ha Noi Old Quarter. Trip
              ends.
            </li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 8,
        label: `Day 8 : Hanoi departure`,
        children: (
          <ul>
            <li>
              Post breakfast spend some leisure time until check out and
              transfer to the airport for the departure flight back to your
              sweet home.
            </li>
          </ul>
        ),
      },
    ],
    inclusions: [
      `Accommodation at all places including breakfast on
Double sharing.`,
      `Sharing transfer Hanoi – Halong – Hanoi`,
      `Day tour on a private basis`,
      `Internal flights with 20kgs of luggage allowance.`,
      `Meals as mentioned in the program (7 breakfast + 1 lunch )`,
      `Entry fee for all mentioned sites for visit.`,
      `Boat trips as mentioned in the program`,
      `Qualified local tour guides during the whole trip (except the
break time or program clearly mentioned "WITHOUT
GUIDE")`,
    ],
    exclusions: [
      `Visa Service to Vietnam.`,
      `Meals which are not mentioned in the program.`,
      `Any International Flight and airport tax.`,
      `Single Supplement. Early check-in and late check-out.
Hotel/Room upgrade.`,
      `Drinks, personal expenses and any services not clearly
mentioned in the program.`,
      `Travel Insurance.`,
      `GST & TCS as applicable.`,
      `Wax Museum, cotton animal game, and carnival skills at
Fantasy Park are not included.`,
    ],
  },
  bhutan: {
    id: 2,
    title: "Bhutan",
    name: "bhutan",
    image: bhutan,
    attachedFile: bhutanPackage,
    location: "Thimphu, Paro, Phuentsholing",
    startDate: "6 Night 7 Days",
    endDate: "",
    rating: "4.9",
    price: "19999",
    bannerImages: [bhutan1, bhutan2, bhutan3, bhutan4, bhutan5],
    photosOfTheTrip: [bhutan1, bhutan2, bhutan3, bhutan4, bhutan5],
    itenary: [
      {
        key: 0,
        label: "Day 1: SILIGURI TO PHUENTSHOLING",
        children: (
          <ul>
            <li>Arrive at Bagdogra Airport, Siliguri before 12:00 Noon</li>
            <li>Meet your co-travellers and our representative</li>
            <li>
              Start the journey towards the Land of Thunder Dragon, Bhutan
            </li>
            <li>
              Arrive in Phuentsholing by evening and check into the hotel for a
              relaxed night!
            </li>
          </ul>
        ),
      },
      {
        key: 1,
        label: "Day 2: PHUENTSHOLING TO THIMPHU",
        children: (
          <ul>
            <li>Start Early post breakfast</li>
            <li>
              Proceed towards the immigration office to complete the
              documentation and formalities
            </li>
            <li>
              Once done, explore some of the amazing cafes in Phuentsholing for
              Lunch if time permits
            </li>
            <li>
              Start your journey towards the beautiful capital of Bhutan -
              Thimphu
            </li>
            <li>Arrive and check into the hotel</li>
            <li>Explore nearby markets and surroundings</li>
            <li>Dinner and stay at the hotel for the night</li>
          </ul>
        ),
      },
      {
        key: 2,
        label: "Day 3: THIMPHU SIGHTSEEING",
        children: (
          <ul>
            <li>Start early post breakfast.</li>
            <li>Sightseeing in Thimphu :</li>
            <ul>
              <li>Golden Budha Statue</li>
              <li>Simply Bhutan</li>
              <li>Gangtey Monastery</li>
              <li>Local Market</li>
            </ul>
            <li>Cafe crawling in night</li>
          </ul>
        ),
      },
      {
        key: 3,
        label: "Day 4: THIMPHU TO PUNAKHA",
        children: (
          <ul>
            <li>Start Early post breakfast for an exciting day</li>
            <li>
              Start the journey towards Punakha amidst the breathtaking
              landscape
            </li>
            <li>
              Cross the famous Dochula and proceed towards Punakha and visit the
              Punakha Dzong
            </li>
            <li>Dinner and overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 4,
        label: "Day 5: PUNAKHA TO PARO",
        children: (
          <ul>
            <li>
              Post a sumptuous breakfast, we start our journey towards the
              beautiful city of Paro.
            </li>
            <li>
              The historical town of Paro lies at the centre of Paro Valley
              along the banks of the Paro Chhu River, and is blessed in rich
              sites and ancient buildings.
            </li>
            <li>
              Recognised as one of the most fertile as well as historic valleys
              of Bhutan, it is home to as many as 155 temples and monasteries
              dating back to the 14th century
            </li>
            <li>
              The journey and the landscape would leave you awestruck as we
              proceed Explore Paro and the various landmarks and check them off
              our list.
            </li>
            <li>Dinner and overnight stay at the hotel</li>
          </ul>
        ),
      },
      {
        key: 5,
        label: "Day 6: PARO TO TIGER'S NEST TO PARO",
        children: (
          <ul>
            <li>
              Strap on your trekking shoes and get ready for an adventurous day!
            </li>
            <li>
              We start our ride towards the starting point of the Tiger's Nest
              Trek post breakfast
            </li>
            <li>
              Upon arrival, we start the ascend on foot walking through the lush
              greens and breathing in the fresh air
            </li>
            <li>
              Enjoy the successful climb atop the beautifully placed Taktsang
              Monastery and enjoy the rich heritage of the place and the
              beautiful view it offers!
            </li>
            <li>
              Start the descend to reach the bottom in time to have enough time
              to explore the Paro City
            </li>
            <li>Dinner and overnight stay at the hotel</li>
          </ul>
        ),
      },
      {
        key: 6,
        label: "Day 7: PARO TO SILIGURI",
        children: (
          <ul>
            <li>
              Start early from Paro to cover the longest journey of the trip in
              time!
            </li>
            <li>
              Head back towards Phuentsholing and trace back the route till
              Siliguri
            </li>
            <li>
              Enjoy the beautiful roads surrounded by lush greens and the
              feeling of that fresh air
            </li>
            <li>
              Dinner and overnight stay at the hotel upon arrival in Siliguri
            </li>
          </ul>
        ),
      },
      {
        key: 7,
        label: "Day 8: DEPARTURE",
        children: (
          <ul>
            <li>Breakfast at the hotel</li>
            <li>Transfers from the hotel to the airport</li>
            <li>
              Bid adieu to a memorable trip taking back with you memories worth
              a lifetime!
            </li>
          </ul>
        ),
      },
    ],
    inclusions: [
      "Accommodation for 7 Nights on Double Sharing basis in Premium Certified 3 Star hotels",
      "7 Breakfasts and 7 Dinners starting from Day 1 Dinner to Day 8 Breakfast",
      "All Sightseeing as per itinerary in SUV/ Minibus",
      "Airport Pick up & Drop",
      "Bhutan Permit Charges",
      "Sightseeing and Excursions as per the itinerary",
      "Dedicated Bhutanese Tour Guide & Tour Captain(s)",
      "First-Aid medical kits",
    ],
    exclusions: [
      "GST (5%) is applicable extra.",
      "Any kind of food or beverage that is not included in the package like alcoholic drinks, mineral water, meals/refreshments/lunches on the highway.",
      "Any personal expenses like a tip to the drivers, entry to monuments/monasteries, camera/video camera charges, camel safari, river rafting, laundry, telephone bills, tips, etc.",
      "Any cost arising due to natural calamities like landslides, roadblocks etc. (to be borne directly by the customer on the spot)",
      "Anything not mentioned in the inclusions.",
      "Any entrance fee to the monastery and monuments",
    ],
  },
  thailand: {
    id: 3,
    title: "Thailand",
    name: "thailand",
    image: thailand,
    location: "Phuket, Phi Phi Island, Bangkok",
    startDate: "7 Night 8 Days",
    attachedFile: thailandPackage,
    endDate: "",
    rating: "4.7",
    price: "39999",
    bannerImages: [
      thailand1,
      thailand3,
      thailand4,
      thailand5,
      thailand6,
      thailand7,
      thailand8,
    ],
    photosOfTheTrip: [
      thailand1,
      thailand3,
      thailand4,
      thailand5,
      thailand6,
      thailand7,
      thailand8,
    ],
    itenary: [
      {
        key: 0,
        label: "Day 1 ARRIVAL IN PHUKET | BANGLA WALKING STREET PUB CRAWL",
        children: (
          <ul>
            <li>
              After arrival at Phuket airport you will be transferred to hotel
              on private basis.
            </li>
            <li>
              Rest of the day will be leisure in the hotel & water sports at the
              beach.
            </li>
            <li>
              Later in evening, we will visit Bangla Walking Street in Patong
              for amazing nightlife and hop clubs.
            </li>
            <li>It is one of the best places to explore Phuket Nightlife.</li>
            <li>Overnight stay at Phuket hotel.</li>
          </ul>
        ),
      },
      {
        key: 1,
        label: "Day 2 KOH SAMUI | EXPLORING THE ISLAND | FULL MOON PARTY",
        children: (
          <ul>
            <li>
              After breakfast you will be transferred to Koh Samui by transfer
              on SIC basis.
            </li>
            <li>
              Taking our speedboat to the island that's a naturally pleasant,
              exciting, and wild at the same time.
            </li>
            <li>
              We will check into our rooms, change into beachwear, and head
              towards the beach. Post a quick swim and fun we’ll get ready and
              head towards the party island “Koh Phangan” on a speed boat.
            </li>
            <li>
              It is the day will attend what you probably came in for - The Full
              Moon Party!. With Alcohol Buckets in our hands and Neon Paints on
              our skin, We will experience the best party of our lives that will
              go until the sunrise.
            </li>
            <li>Overnight stay at Koh Samui hotel.</li>
          </ul>
        ),
      },
      {
        key: 2,
        label: "Day 3 A LEISURE DAY IN KOH SAMUI",
        children: (
          <ul>
            <li>
              After full night wild party, this will be a day to rejuvenate
              yourself and get some sleep.
            </li>
            <li>
              You can head towards the beach, waterfalls and chill and take the
              day slow to energize yourself again for the upcoming fun.
            </li>
            <li>Overnight stay at Koh Samui hotel.</li>
          </ul>
        ),
      },
      {
        key: 3,
        label: "Day 4 TRANSFER TO PHI PHI | ISLAND EXPLORATION",
        children: (
          <ul>
            <li>
              It’s time to say goodbye to Samui, transfer to a new island, KOH
              PHI PHI which will take half a day with multiple transfers over
              Ferry, Bus & a Speed Boat (total 4-5 hours including transit).
            </li>
            <li>
              After checking in to your hotel it’s time to watch one of the best
              sunsets of your trip from a cliff point.
            </li>
            <li>
              Post sunset party at backpackers bar & slinky beach bar and you
              will have one of the wildest nights of your life.
            </li>
            <li>Overnight stay at Phi Phi hotel.</li>
          </ul>
        ),
      },
      {
        key: 4,
        label:
          "Day 5 FULL DAY FOUR ISLAND TOUR BY SPEED BOAT | SNORKELLING | MAYA BAY",
        children: (
          <ul>
            <li>
              After breakfast, we will head for an island tour across Maya Bay,
              Bamboo Island, Monkey Island & Viking Cave.
            </li>
            <li>
              Enjoy a full-day Four Island tour from Phi Phi by hopping around
              the beautiful islands by a speedboat.
            </li>
            <li>
              There will be multiple snorkeling spots in crystal clear blue
              waters, and so we’ll then go for snorkeling experiencing
              underwater life at its best.
            </li>
            <li>
              Post sunset, enjoy the most famous pool party in Thailand “Ibiza
              Pool Party”.
            </li>
            <li>Overnight stay at Phi Phi hotel.</li>
          </ul>
        ),
      },
      {
        key: 5,
        label: "Day 6 PHI PHI TO KRABI | KRABI AO NANG",
        children: (
          <ul>
            <li>
              It’s one of the last days here in Thailand so after breakfast we
              will check out from our hotel and get to Krabi.
            </li>
            <li>
              In the daytime we will do activities like ATV ride, shopping or
              taking that last dip into the ocean.
            </li>
            <li>
              You can also shop from the vast options of stalls selling
              locally-made crafts and clothing.
            </li>
            <li>
              The markets are also a culinary treat, serving some of the best
              flavors Thailand has to offer.
            </li>
            <li>Overnight party & stay at Krabi hotel.</li>
          </ul>
        ),
      },
      {
        key: 6,
        label: "Day 7 Departure",
        children: (
          <ul>
            <li>Give one last look at the island.</li>
            <li>
              It might be our last day but remember you’re taking back crazy
              memories, a few ticks on your bucket list, and a strong bond of
              friendships.
            </li>
            <li>We know by now Thailand has your hearts.</li>
            <li>
              You will take your rides from Krabi to Phuket to catch your
              flights to your respective destinations.
            </li>
          </ul>
        ),
      },
    ],
    inclusions: [
      "Accommodation for 6 nights in Hotels with Breakfast.",
      "1 Nights in Phuket",
      "2 Nights in Koh Samui",
      "2 Nights in Phi Phi",
      "1 Night in Krabi",
      "Transfers To & From Airport - Hotel Transfers to Islands & Hotels.",
      "Full Moon Party transfers from Koh Samui.",
      "Welcome Drinks on the day of arrival.",
      "Full day excursion to Maya Bay with light snacks.",
      "Entire travel as per the itinerary English speaking guide for the entire trip.",
    ],
    exclusions: [
      "GST (5%) are applicable extra.",
      "Water Sports or any other activity other than mentioned in the itinerary.",
      "Any kind of food or beverage that is not included in the package like alcoholic drinks, mineral water, meals/refreshments/lunches on the highway. Transportation in Thailand apart from the Day trips, Hotel pickup and drop, Ferry Port transfers.",
      "Any personal expenses like a tip to the drivers, camera/video camera charges, laundry, telephone bills, tips, etc.",
      "Any cost arising due to natural calamities like landslides, roadblocks etc. (to be borne directly by the customer on the spot).",
      "Anything not mentioned in the inclusions.",
      "Cost arises due to change or delay in flight timings.",
      "Visa & Medical Insurance",
      "Any Expenses of personal nature.",
      "Any additional activities during the tours",
    ],
  },
  cambodia: {
    id: 4,
    title: "Vietnam Cambodia",
    name: "cambodia",
    location: "Cambodia",
    startDate: "9 Night 10 Days",
    attachedFile: thailandPackage,
    endDate: "",
    rating: "4.7",
    price: "39999",
    bannerImages: [],
    photosOfTheTrip: [],
    itenary: [
      {
        key: 1,
        label: "Day 1: Arrival in Hanoi – Half day city tour",
        children: (
          <ul>
            <li>
              The tour guide & driver will be welcoming you at Noi Bai airport;
              it takes us 35 minutes to drive to the centre of Hanoi and then
              check in at the hotel.
            </li>
            <li>
              Let’s start our overall view of the capital with a walking tour
              around the Old Quarter of Hanoi, stopping at Ngoc Son Temple
              located on the Hoan Kiem Lake (the “Sword” Lake).
            </li>
            <li>Overnight stay at the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 2,
        label: "Day 2: Hanoi – Halong Baycruise – Hanoi",
        children: (
          <ul>
            <li>
              8h15-8h30: Our Tour guide will pick up you at the hotel in Ha Noi
              Old Quarter then depart to Halong Bay by Expressway Transfer.
            </li>
            <li>
              12h00 – 12h30: Arrive Tuan Chau Harbor. Welcome to Cozy Bay
              Premium Cruise. We will take you on a 5-6 hour journey via 2
              day-sleeper boat cruise route which is renowned for its striking,
              passages uninhabited islets and outcrops that have been given
              imaginative names by the locals, due to their perceived shapes
              while having lunch on the boat.
            </li>
            <li>
              14h00: You will visit Surprising Cave to see a lot of stalagmites
              and stalactites with different shapes of sea life.
            </li>
            <li>
              15h00: Visit Titop Island with a sandy beach to the towering
              limestone mountain with an excellent backdrop of the Bay, and
              climb up Titop Peak for a panoramic view of the bay-like paradise.
            </li>
            <li>
              16h00: Then go kayaking or on a bamboo boat in Luon Cave to
              discover the beautiful Lagoon.
            </li>
            <li>
              17h00 – 17h30: Cruising back to Tuan Chau harbour. There is a lot
              of time for enjoying sunset parties with tea, and cakes,
              sunbathing on the upper deck with music and enjoying great fun of
              group touring fellows among the world's iconic natural scenery.
            </li>
            <li>
              18h00 – 18h30: We get on the bus to back Hanoi by Expressway, and
              have a short break for 20-30 minutes.
            </li>
            <li>
              21h00 – 21h30: Arrive at the hotel in Ha Noi Old Quarter. Trip
              ends. Overnight stay at the Hotel.
            </li>
          </ul>
        ),
      },
      {
        key: 3,
        label:
          "Day 3: Hanoi – Ninh Binh – Trang An – Mua cave - Departure to Da nang",
        children: (
          <ul>
            <li>
              After breakfast, we pick you up at your hotel in Old Quater, and
              depart for Hoa Lu-Tam Coc, enjoying the beautiful sceneries of the
              rural areas on the way.
            </li>
            <li>
              10h30: After arriving in Ninh Binh province, the guide takes you
              to visit Hoa Lu - the Ancient Royal capital of Vietnam. We will
              visit Dinh King temple and Le King temple, in the 10th century,
              find out the heroic histories of these two Kings in Chinese Wars.
              After that, we go to Tam Coc (10 km far from Hoa Lu).
            </li>
            <li>
              11h30: Visit Mua Cave (Mua means Dance) with 500 steps up to the
              Top of Mua Moutain. On the Top, you can overview all the nice
              scenes of Hoa Lu.
            </li>
            <li>Have a lunch break.</li>
            <li>
              14h00: Depart to visit Trang An Eco-Tourism attraction, there is a
              beautiful scenery landscape, you will board a small boat to visit
              Trang An, and you will see majestic limestone cliffs and
              mysterious yet inviting caves, as well as countryside sights, like
              peaceful streams, rice paddies and deserted green fields. Take a
              boat trip to visit the cave complex: Sang Cave, Toi Cave, Ba Giot
              Cave, and Nau Ruou in mystery and wildness, and see the scenery of
              mountains and rivers Ha Long on the land of Ninh Binh.
            </li>
            <li>
              17h00: Get in the car and go back to Ha Noi. Reach the hotel by 7
              pm and take the Night flight to Da Nang. Stay In a 4 star hotel in
              Da Nang.
            </li>
          </ul>
        ),
      },
      {
        key: 4,
        label: "Day 4: Leisure day in Da nang.Hoi AN old town visit.",
        children: (
          <ul>
            <li>
              It's a leisure today, after breakfast you can visit the Beaches of
              Da Nang.
            </li>
            <li>
              In the evening, enjoy the Basket boat ride and explore Hoi An
              ancient town, visiting the Japanese Pagoda Bridge, Chinese
              streets, old Phung Hung houses, Hoi An’s market and ancient port.
            </li>
            <li>
              On the 1st and 15th of every lunar month, Hoi An’s ancient town is
              lit up by hundreds of colourful lanterns, giving it an extra
              portion of sparkle.
            </li>
            <li>
              Enjoy the city’s transformation and stroll through this quaint,
              glowing fairytale town in your free time.
            </li>
            <li>Overnight stay at the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 5,
        label: "Day 5: BANA HILLS TOUR",
        children: (
          <ul>
            <li>
              07:45 - 8:00: Pick up from the hotel in Danang and start transfer
              to Ba Na Hills.
            </li>
            <li>
              Check-in at the Suoi Mo Cable Car Station and transfer to
              Marseille Station.
            </li>
            <li>
              Explore Golden Bridge (The Hands of The Gods) - majestic and
              impressive as a scene in “Game of Thrones” or “The Lords of the
              Rings”.
            </li>
            <li>
              Drop by Le Jardin D’Amour (the Flower Garden of Love) with 9
              gardens of Le Jardin D’Amour are 9 amusing tales in 9 varying
              architectures, which harmonize so well in a visual sonata of
              beauty.
            </li>
            <li>Continue the trip with Linh Ung Pagoda & Debay Wine Cellar.</li>
            <li>
              Afternoon: Discover and play games at Fantasy Park - a perfect
              tourist attraction for an entire family as well as explore King
              Peak.
            </li>
            <li>
              Return to the Cable Car Station and back to the Gateway then
              transfer back to the hotel.
            </li>
            <li>
              Take a night flight to Ho Chi Minh City, and enjoy your last day
              at Bui Vien Street (the party street of Vietnam).
            </li>
          </ul>
        ),
      },
      {
        key: 6,
        label: "Day 6: Ho Chi Minh to Phnom Penh (Cambodia)",
        children: (
          <ul>
            <li>
              Post breakfast start your journey towards the new leg of the trip.
            </li>
            <li>Travel from Ho Chi Minh to Phnom Penh by bus.</li>
            <li>Check into your hotel in Phnom Penh.</li>
            <li>
              Leisure day: Explore the city at your own pace. You can visit
              local markets, try Cambodian cuisine, or relax at a café.
            </li>
            <li>Overnight stay at the Party Hostel.</li>
          </ul>
        ),
      },
      {
        key: 7,
        label: "Day 7: Phnom Penh Day Tour",
        children: (
          <ul>
            <li>
              Visit the Royal Palace and Silver Pagoda to explore the rich
              history of Cambodia.
            </li>
            <li>
              Explore the Tuol Sleng Genocide Museum and the Killing Fields to
              learn about Cambodia's tragic past.
            </li>
            <li>Enjoy a sunset cruise on the Mekong River.</li>
            <li>Overnight stay at the Party Hostel.</li>
          </ul>
        ),
      },
      {
        key: 8,
        label: "Day 8: Phnom Penh to Siem Reap",
        children: (
          <ul>
            <li>Travel from Phnom Penh to Siem Reap by bus.</li>
            <li>Check into your hotel in Siem Reap.</li>
            <li>
              Self-exploration of the town in the evening: Visit the vibrant Pub
              Street, explore the local markets, and try some street food.
            </li>
            <li>Overnight stay at the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 9,
        label: "Day 9: Full-day Tour of Angkor Wat Temple",
        children: (
          <ul>
            <li>
              Visit the iconic Angkor Wat Temple complex, known for its stunning
              architecture and intricate carvings.
            </li>
            <li>
              Explore other temples in the area, such as Bayon Temple and Ta
              Prohm Temple.
            </li>
            <li>
              Enjoy a traditional Cambodian dinner with a cultural performance
              in the evening.
            </li>
            <li>Overnight stay at the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 10,
        label: "Day 10: Checkout & Departure",
        children: (
          <ul>
            <li>Check out from your hotel in Siem Reap.</li>
            <li>
              Depending on your departure time, you may have some free time for
              last-minute shopping or sightseeing.
            </li>
          </ul>
        ),
      },
    ],
    inclusions: [
      "7-night stays in a 3/4 star hotel with breakfast",
      "2-night stays in a Party Hostel without breakfast",
      "Flights (Hanoi - Da Nang - Ho Chi Minh)",
      "Bus transfers (Ho Chi Minh - Phnom Penh - Siem Reap)",
      "All-day tours with Hotel pickup and drop (Join-in tours)",
      "Sharing transfer Hanoi – Halong – Hanoi",
      "Day tour on a group basis",
      "Meals as mentioned in the program",
      "Entry fee for all mentioned sites for visit",
      "Boat trips as mentioned in the program",
      "Qualified local tour guides during the whole trip (except the break time or program mentioned 'WITHOUT GUIDE')",
    ],
    exclusions: [
      "Visa Service to Vietnam & Cambodia",
      "Meals that are not mentioned in the program",
      "Any International Flight (Flight from India and back) and airport tax",
      "Single Supplement, Early check-in and late check-out",
      "Hotel/Room upgrade",
      "Drinks, personal expenses, and any services not mentioned in the program",
      "Travel Insurance",
      "GST & TCS as applicable",
    ],
  },
  vietnam6d5n: {
    id: 5,
    title: "Vietnam 6D5N",
    name: "vietnam6d5n",
    location: "Vietnam",
    startDate: "5 Night 6 Days",
    attachedFile: thailandPackage,
    endDate: "",
    rating: "4.7",
    price: "39999",
    bannerImages: [],
    photosOfTheTrip: [],
    itenary: [
      {
        key: 1,
        label: "Day 1: Arrival in Hanoi – Halfday city walking tour",
        children: (
          <ul>
            <li>
              The tour guide & driver will welcome you at Noi Bai airport; it
              takes us 45 minutes to drive to the centre of Hanoi and then check
              in at the hotel.
            </li>
            <li>
              Let’s start our city tour from the overall view of the capital
              with a walking tour around Hanoi's Old Quarter, stopping at Ngoc
              Son Temple located on the Hoan Kiem Lake (the “Sword” Lake). Visit
              the train street and grab famous egg coffee while enjoying the
              train passing.
            </li>
            <li>Overnight stay at the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 2,
        label: "Day 2: Hanoi – Halong Bay cruise – Hanoi",
        children: (
          <ul>
            <li>
              8h15-8h30: Our Tour guide will pick up you at the hotel in Ha Noi
              Old Quarter then depart to Halong Bay by Expressway Transfer.
            </li>
            <li>
              12h00 – 12h30: Arrive Tuan Chau Harbor. Welcome to Cozy Bay
              Premium Cruise. We will take you on a 5-6 hour journey via 2
              day-sleeper boat cruise route which is renowned for its striking,
              passages uninhabited islets and outcrops that have been given
              imaginative names by the locals, due to their perceived shapes
              while having lunch on the boat.
            </li>
            <li>
              15h00: Visit Titop Island with a sandy beach to the towering
              limestone mountain with an excellent backdrop of the Bay, and
              climb up Titop Peak for a panoramic view of the bay-like paradise.
            </li>
            <li>
              16h00: Then do kayaking or on a bamboo boat in Luon Cave to
              discover the beautiful Lagoon.
            </li>
            <li>
              17h00 – 17h30: Cruising back to Tuan Chau harbour. There is a lot
              of time for enjoying sunset parties with tea, and cakes,
              sunbathing on the upper deck with music and enjoying great fun of
              group touring fellows among the world's iconic natural scenery.
            </li>
            <li>
              18h00 – 18h30: We get on the bus to back Hanoi by Expressway, and
              have a short break for 20-30 minutes.
            </li>
            <li>
              21h00 – 21h30: Arrive at the hotel in Ha Noi Old Quarter. Trip
              ends. Overnight stay in the Hotel.
            </li>
          </ul>
        ),
      },
      {
        key: 3,
        label: "Day 3: Hanoi – fly to Danang – Da Nang stay",
        children: (
          <ul>
            <li>
              You are free till transfer to the airport for the flight to
              Danang.
            </li>
            <li>
              On arrival, be picked up at the hostel in Hoi An for check-in.
            </li>
            <li>
              Visit Marble Mountain situated near Da Nang, In the evening, enjoy
              the Basket boat ride and explore Hoi An ancient town, visiting the
              Japanese Pagoda Bridge, Chinese streets, old Phung Hung houses,
              Hoi An’s market and ancient port.
            </li>
            <li>
              On the 1st and 15th of every lunar month, Hoi An’s ancient town is
              lit up by hundreds of colourful lanterns, giving it an extra
              portion of sparkle.
            </li>
            <li>
              Enjoy the city’s transformation and stroll through this quaint,
              glowing fairytale town in your free time.
            </li>
            <li>Overnight stay in the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 4,
        label: "Day 4: BANA HILLS TOUR",
        children: (
          <ul>
            <li>
              07:45 - 8:00: Pick up from the hotel in Danang and start transfer
              to Ba Na Hills.
            </li>
            <li>
              Check-in at the Suoi Mo Cable Car Station and transfer to
              Marseille Station.
            </li>
            <li>
              Explore Golden Bridge (The Hands of The Gods) - majestic and
              impressive as a scene in “Game of Thrones” or “ The Lords of the
              Rings”.
            </li>
            <li>
              Drop by Le Jardin D’Amour (the Flower Garden of Love) with 9
              gardens of Le Jardin D’Amour are 9 amusing tales in 9 varying
              architectures, which harmonize so well in a visual sonata of
              beauty.
            </li>
            <li>Continue the trip with Linh Ung Pagoda.</li>
            <li>
              Afternoon: Discover and play games at Fantasy Park - a perfect
              tourist attraction for an entire family as well as explore King
              Peak.
            </li>
            <li>
              Return to the Cable Car Station and back to the Gateway then
              transfer back to the hotel.
            </li>
            <li>Overnight stay in the Hotel.</li>
          </ul>
        ),
      },
      {
        key: 5,
        label: "Day 5: Ho Chi Minh arrival – Walking tour",
        children: (
          <ul>
            <li>
              After breakfast take the flight from Da Nang to Ho Chi Minh City.
            </li>
            <li>
              The tour guide & driver will welcome you at the airport; it takes
              us 20 minutes to drive to the centre of HCM and then check in at
              the hotel.
            </li>
            <li>
              From 5 pm, take the walking tour to explore Saigon and immerse
              yourself in the city's heart between frenetic modernity and
              preserved traditions. Through an exclusive tour, the explorer will
              dive into a market frequented only by locals and observe the
              components of Saigonese life. Unique moment: watch the sunset with
              a panoramic view of the city and its surroundings from the highest
              tower in the city: Bitexco Tower. (Tickets not included).
            </li>
            <li>
              The charm will also operate at nightfall when all social classes
              gather on Nguyên Hue Avenue - the most beautiful avenue in the
              city - to relax with the family.
            </li>
            <li>
              End of our services at the end of the Walking Tour (at the HCMC
              Town Hall). Visit Bui Vien street in the night.
            </li>
            <li>Overnight stay at the hotel.</li>
          </ul>
        ),
      },
      {
        key: 6,
        label: "Day 6: Ho Chi Minh city – Cu Chi Tunnel",
        children: (
          <ul>
            <li>
              Depart for the Cu Chi tunnel, after breakfast the trip will take
              approximately one and a half hours of car driving. Upon arrival,
              before exploring the tunnels, you will have a short introduction
              followed by an introductory video about how the tunnels were
              constructed and how the people survived in the harsh conditions of
              wartime.
            </li>
            <li>
              Then, spend your time exploring the remaining area and tunnel
              systems, including the specially constructed living areas with
              kitchens, and bedrooms side by side with other martial facilities
              like storage, weapons factories, field hospitals, and command
              centres helping whoever lives inside the tunnels meet their basic
              needs. Afterwards, special tea and cassava (guerilla food during
              the war) will be served.
            </li>
            <li>
              Break time for relaxation or time for those who want to try the
              real shooting gun.
            </li>
            <li>
              After the visit, return to your hotel and bid a final goodbye to
              Vietnam. Airport drop according to the flight timings.
            </li>
          </ul>
        ),
      },
    ],
    inclusions: [
      `Accommodation at all places including breakfast on a
double sharing basis in a 3-star hotel.`,
      `Sharing transfer Hanoi – Halong – Hanoi`,
      `Day tour on a group basis.`,
      `Meals as mentioned in the program`,
      `Entry fee for all mentioned sites for visit.`,
      `Boat trips as mentioned in the program.`,
      `Qualified local tour guides during the whole trip (except the
break time or program mentioned "WITHOUT GUIDE")`,
    ],
    exclusions: [
      `Visa Service to Vietnam.`,
      `Meals which are not mentioned in the program.`,
      `Any International Flight and airport tax.`,
      `Single Supplement. Early check-in and late check-out.
Hotel/Room upgrade.`,
      `Drinks, personal expenses and any services not clearly
mentioned in the program.`,
      `Travel Insurance.`,
      `GST & TCS as applicable.`,
    ],
  },
};
export const popularPackages = [
  {
    id: 1,
    title: "Vietnam",
    name: "vietnam",
    image: vietnam,
    location: "Hanoi, Ho Chi Minh",
    startDate: "7 Night 8 Days",
    endDate: "",
    rating: "4.8",
    price: "49999",
  },
  {
    id: 2,
    title: "Bhutan",
    name: "bhutan",
    image: bhutan,
    location: "Thimphu, Paro, Phuentsholing",
    startDate: "6 Night 7 Days",
    endDate: "",
    rating: "4.9",
    price: "19999",
  },
  {
    id: 3,
    title: "Thailand",
    name: "thailand",
    image: thailand,
    location: "Phuket, Phi Phi Island, Bangkok",
    startDate: "7 Night 8 Days",
    endDate: "",
    rating: "4.7",
    price: "39999",
  },
];
export const recommendations = [
  {
    id: 1,
    title: "Vietnam",
    image: vietnamR,
    name: "vietnam",
    location: "Hanoi, Ho Chi Minh",
    startDate: "7 Night 8 Days",
    endDate: "",
    rating: "4.8",
    price: "49999",
  },
  {
    id: 2,
    title: "Bhutan",
    name: "bhutan",
    image: bhutanR,
    location: "Thimphu, Paro, Phuentsholing",
    startDate: "6 Night 7 Days",
    endDate: "",
    rating: "4.9",
    price: "19999",
  },
  {
    id: 3,
    title: "Thailand",
    name: "thailand",
    image: thailandR,
    location: "Phuket, Phi Phi Island, Bangkok",
    startDate: "7 Night 8 Days",
    endDate: "",
    rating: "4.7",
    price: "39999",
  },
  {
    id: 4,
    title: "Vietnam Cambodia",
    name: "cambodia",
    image: thailandR,
    location: "Cambodia",
    startDate: "9 Night 10 Days",
    endDate: "",
    rating: "4.7",
    price: "500",
  },
  {
    id: 5,
    title: "Vietnam 6D5N",
    name: "vietnam6d5n",
    image: vietnamR,
    location: "Vietnam",
    startDate: "5 Night 6 Days",
    endDate: "",
    rating: "4.7",
    price: "500",
  },
];
