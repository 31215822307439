import { Button, Typography } from "antd";
import React from "react";
import WhatsApp from "../../../Assets/Images/whatsapp.svg";
import { Link } from "react-router-dom";

const DeskNavBar = () => {
  const { Text } = Typography;
  return (
    <>
      <div className="width-33 text-center">
        <Link to="/">
          {" "}
          <Text>Home</Text>
        </Link>
        <Text className="l-15">About us</Text>
        <Text className="x-15">Contact us</Text>
        <Link to="/privacy-policy">
          {" "}
          <Text>Privacy and policy</Text>
        </Link>
      </div>
      <div className="width-33 d-flex justify-end">
        {" "}
        <a
          href="https://wa.me/+918595864155"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            type="primary"
            icon={<img src={WhatsApp} alt="whatsapp" />}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#068F2C",
            }}
            size="large"
          >
            859-586-4155
          </Button>
        </a>
      </div>
    </>
  );
};

export default DeskNavBar;
