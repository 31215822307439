import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import "./index.scss";
import crossDesign from "../../../../Assets/Images/crossDesign.svg";
import PackageCard from "./PackageCard";
import { popularPackages } from "../../../../Utils/staticData";

const PopularPackage = () => {
  const { Title } = Typography;
  return (
    <div className="popular-package-container">
      <div className="popular-header">
        <img
          src={crossDesign}
          height="77px"
          alt="cross-design"
          className="popular-design"
        />
        <div className="center-title">
          <Title level={1} className="popular-banner-text reset-margin">
            <span className="black-text">Popular</span>{" "}
            <span className="primary-text">Packages</span>
          </Title>
          <Title level={3} className="grey-text popular-description">
            The most popular tour packages presented to you
          </Title>
        </div>
        <div className="carousal-controls">
          <Button
            type="primary"
            icon={<LeftOutlined style={{ color: "#000000" }} />}
            size="large"
            color="success"
            className="arrow-left"
          />
          <Button
            type="primary"
            icon={<RightOutlined style={{ color: "#000000" }} />}
            size="large"
            color="success"
            className="arrow-right"
          />
        </div>
      </div>
      <div className="package-inner-container">
        {popularPackages.map((packageData, index) => (
          <PackageCard packageData={packageData} index={index} />
        ))}
      </div>
      {/* <Row className="popular-body" gutter={[10, 10]}>
        {packages.map(({ link, image }) => (
          <Col md={8}>
            {" "}
            <PackageCard image={image} link={link} />
          </Col>
        ))}
      </Row> */}
    </div>
  );
};

export default PopularPackage;
