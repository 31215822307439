import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { RightOutlined } from "@ant-design/icons";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import "./index.scss";

import fadedLine from "../../Assets/Images/faded-line.svg";
import locationIcon from "../../Assets/Images/location2.svg";
import clock from "../../Assets/Images/clock2.svg";
import { Button, Col, Collapse, Grid, Input, Row, Typography } from "antd";
import { useParams } from "react-router-dom";
import { handleFormScroll, packageDetailsRender } from "../../Utils/helpers";

const InternalPage = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isTablet = !screens.xl;
  const { name } = useParams();
  const {
    title,
    price,
    startDate,
    location,
    itenary,
    rating,
    bannerImages,
    photosOfTheTrip,
    inclusions,
    exclusions,
    attachedFile,
  } = packageDetailsRender(name);
  useEffect(() => {
    window.addEventListener("scroll", handleFormScroll);

    return () => {
      window.removeEventListener("scroll", handleFormScroll);
    };
  }, []); // Empty dependency array ensures that the effect runs once (like componentDidMount)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="internal-page">
      <main>
        <div className="package-details">
          <div className="internal-image-container">
            <Swiper
              slidesPerView={isTablet ? 1 : 4}
              spaceBetween={30}
              // centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {bannerImages.map((image) => (
                <SwiperSlide>
                  <img
                    src={image}
                    alt="bg-container"
                    width="420px"
                    height="538px"
                    className="main-image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <Row className="view-package-middle-body-wrapper">
            <Col className="package-info" md={14}>
              <h2 className="internal-page-package-title">{title}</h2>
              <div className="rating">
                <span>⭐⭐⭐⭐⭐</span>
                <span>({rating} Rating)</span>
                <a href="#">135 Reviews</a>
              </div>
              <div className="meta-container">
                {" "}
                <div className="package-meta">
                  <img
                    src={locationIcon}
                    width="24px"
                    height="24px"
                    alt="location"
                  />
                  <span>{location}</span>
                </div>
                <div className="package-meta">
                  <img src={clock} width="24px" height="24px" alt="location" />
                  <span>{startDate}</span>
                </div>
              </div>

              <div className="internal-package-price">
                <span className="digit">{price}/- </span>Onwards
              </div>
              <div className="package-actions">
                <a
                  href="https://wa.me/+918595864155"
                  class="whatsapp_float"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <button className="btn enquire">Enquire and Book</button>
                </a>

                <a
                  href={attachedFile}
                  download={`${title.trim().toLowerCase()}-package`}
                >
                  <button
                    className="btn download"
                    // href={attachedFile}
                    // target="_blank"
                  >
                    Download Itinerary
                  </button>
                </a>
              </div>
              <div className="trip-details-body">
                <div className="itenary-container">
                  <div className="itenary-header">
                    <Typography className="detail-heading">
                      ITINERARY
                    </Typography>
                    <img src={fadedLine} alt="faded-line" width="500px" />
                  </div>
                  <Collapse
                    items={itenary}
                    ghost
                    expandIconPosition="end"
                    className="trip-details-accordion"
                  />
                </div>
              </div>
              <div className="inclusions-container">
                <Typography className="inclusions-header">
                  Inclusions
                </Typography>
                <ul className="inclusion-exclusion-points">
                  {inclusions.map((inclusion) => (
                    <li>{inclusion}</li>
                  ))}
                </ul>
              </div>
              <div className="exclusions-container">
                <Typography className="exclusions-header">Exclusion</Typography>
                <ul className="inclusion-exclusion-points">
                  {exclusions.map((exclusion) => (
                    <li>{exclusion}</li>
                  ))}
                </ul>
              </div>
              <div class="photos-section">
                {/* Make it 1:1 */}
                <h2>PHOTOS OF THE TRIP</h2>
                {isTablet ? (
                  <Swiper
                    slidesPerView={isTablet ? 1 : 4}
                    spaceBetween={30}
                    // centeredSlides={true}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {bannerImages.map((image) => (
                      <SwiperSlide>
                        <img
                          src={image}
                          alt="bg-container"
                          width="420px"
                          height="538px"
                          className="main-image"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <Row gutter={[0, 10]}>
                    {photosOfTheTrip.map((photo) => (
                      <Col md={6}>
                        {" "}
                        <img
                          src={photo}
                          alt="pic-1"
                          className="photo-of-the-trip"
                          // width="254px"
                        />
                      </Col>
                    ))}
                  </Row>
                )}

                <div className="view-all-btn-container">
                  <Button type="text" icon={<RightOutlined />}>
                    View all photos
                  </Button>
                </div>
              </div>
            </Col>
            <Col id="internal-contact-form" className="contact-form" md={8}>
              <h2>Get In Touch</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <label for="fullName">Full Name</label>
                <Input
                  type="text"
                  id="fullName"
                  placeholder="Enter your full name"
                />

                <label for="contactNumber">Contact Number</label>
                <Input
                  type="text"
                  id="contactNumber"
                  placeholder="Enter your contact number"
                />

                <label for="email">Email id</label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Enter your email id"
                />

                <label for="destination">Preferred Destination</label>
                <Input
                  type="text"
                  id="destination"
                  placeholder="Enter your preferred destination"
                />

                <button>Submit details</button>
              </form>
            </Col>
          </Row>
        </div>
      </main>
    </div>
  );
};

export default InternalPage;
