import { Button } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const ChooseCard = ({ icon, title, description, id }) => {
  return (
    <div className={`chose-card-container ${id === 2 && "shadow-card"}`}>
      <div className="icon-container">
        <img src={icon} alt="icon" width="30px" />
      </div>
      <Title level={2} className="choose-us-title">
        {title}
      </Title>
      <Title
        level={5}
        className="title-margin-custom grey-text choose-card-description"
      >
        {description}
      </Title>
      <Button className="mt-15 primary-text" type="text">
        Read More
      </Button>
    </div>
  );
};

export default ChooseCard;
