import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";

const Explore = () => {
  return (
    <div className="explore-container">
      <Title level={1} className="white-text explore-text">
        Explore the World Around You
      </Title>
      <Title level={5} className="explore-description">
        Take a break from everyday life, Plan trips and explore your favourite
        destinations.
      </Title>
      <Button
        className="contained-primary"
        size="large"
        style={{ width: "258px" }}
      >
        Lets Plan your Trip
        <RightOutlined />
      </Button>
    </div>
  );
};

export default Explore;
