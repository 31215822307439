import { packageDetails } from "./staticData";

export const scrollToTop = () =>
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const handleFormScroll = (e) => {
  const contactForm = document.getElementById("internal-contact-form");
  const viewPackageContainer = document.querySelector(
    ".view-package-middle-body-wrapper"
  );
  const contactFormTop = contactForm?.getBoundingClientRect()?.top;
  const viewPackageFormTop = viewPackageContainer?.getBoundingClientRect()?.top;
  if (contactFormTop >= 90) {
    contactForm.classList.add("is-sticky-form");
  } else if (viewPackageFormTop <= -1592) {
    contactForm.classList.remove("is-sticky-form");
    contactForm.classList.add("bottom-form");
  }
  // else if (containerTop > 0) {
  //   clientSay?.classList.remove("is-sticky-client-say");
  //   clientSay?.classList.add("client-say-left-container");
  //   clientSay?.classList.remove("client-say-left-container-bottom");
  // } else if (containerTop > -1500) {
  //   clientSay?.classList.remove("is-sticky-client-say");
  //   clientSay?.classList.remove("client-say-left-container");
  //   clientSay?.classList.add("client-say-left-container-bottom");
  // }
};

export const packageDetailsRender = (name) => {
  switch (name) {
    case "vietnam":
      return packageDetails.vietnam;
    case "bhutan":
      return packageDetails.bhutan;
    case "thailand":
      return packageDetails.thailand;
    case "cambodia":
      return packageDetails.cambodia;
    case "vietnam6d5n":
      return packageDetails.vietnam6d5n;
    default:
      return;
  }
};
