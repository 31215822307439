import * as React from "react";
import { useRoutes } from "react-router-dom";
import DashboardLayout from "../Layouts/Dashboard";
import Home from "../Containers/Home";
import PrivacyPolicy from "../Containers/PrivacyPolicy";
import InternalPage from "../Containers/InternalPage";

// import { isAuth } from "./Utils/helpers";

function Router() {
  let element = useRoutes([
    {
      path: "/",
      // element: <Login />,
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Home /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/view-package/:name", element: <InternalPage /> },
      ],
      //   element: token ? <Navigate to="/dashboard" /> : <Login />,
    },
    // {
    //   path: "/dashboard",
    //   // element: <DashboardLayout />,
    //   element: isAuth() ? <DashboardLayout /> : <Navigate to="/" />,
    //   children: routeFilter(),
    // },
    // {
    //   path: "*",
    //   element: <Navigate to="/" />,
    // },
  ]);

  return element;
}

export default Router;
