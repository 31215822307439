import React from "react";
import "./index.scss";
import crossDesign from "../../../../Assets/Images/crossDesign.svg";
import Title from "antd/es/typography/Title";
import { Col, Row } from "antd";
import { whyChoseCards } from "../../../../Utils/staticData";
import ChooseCard from "./ChooseCard";

const WhyChooseUs = () => {
  return (
    <div className="why-chose-container">
      <div className="why-chose-header recommendations-header">
        <div className="recommendations-design-container">
          {" "}
          <img
            src={crossDesign}
            height="77px"
            alt="cross-design"
            className="recommendation-design"
          />
        </div>

        <div className="center-title why-choose-title-1 recommendations-title ">
          <Title level={1} className="recommendations-banner-text reset-margin">
            <span className="black-text">Why</span>{" "}
            <span className="primary-text">Choose Us</span>?
          </Title>
          <Title
            level={3}
            className="grey-text recommendations-description popular-description"
          >
            Try a variety of benefits when using our services
          </Title>
        </div>
      </div>
      <div className="why-chose-1-body">
        {whyChoseCards.map((data) => (
          <ChooseCard {...data} />
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
