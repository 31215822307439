import React from "react";
import { Link } from "react-router-dom";

const PackageCard = ({ packageData, index }) => {
  return (
    <Link to={`/view-package/${packageData?.name}`} preventScrollReset={true}>
      {" "}
      <img
        src={packageData?.image}
        alt="travel-card"
        className={`package-image ${index === 1 && "center-package-image"}`}
      />
    </Link>
  );
};

export default PackageCard;
