import React from "react";
import { Button, Input, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./style.scss";

const Banner = () => {
  const { Title } = Typography;
  const suffix = (
    <Button
      icon={<SearchOutlined />}
      className="banner-search-button contained-primary"
    >
      Search
    </Button>
  );
  return (
    <div className="banner-container">
      <div className="banner-sub-container">
        <Title level={1} className="banner-text">
          Worldwide Community of Travel Enthusiasts Crafting Lasting Memories
        </Title>
        <Input
          placeholder="Where you want to go"
          size="large"
          suffix={suffix}
        />
      </div>
    </div>
  );
};

export default Banner;
