import React from "react";
import "./index.scss";
import crossDesign from "../../../../Assets/Images/crossDesign.svg";
import touchImage from "../../../../Assets/Images/touch-image.png";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Button, Form, Input } from "antd";

const GetInTouch = () => {
  const [form] = Form.useForm();
  return (
    <div className="get-in-touch-container">
      <img
        src={crossDesign}
        height="77px"
        alt="cross-design"
        className="touch-design-1"
      />
      <div className="touch-form-container">
        <Title level={1} className="touch-banner-text">
          <span className="black-text">Get in</span>{" "}
          <span className="primary-text">Touch</span>
          <span className="black-text"> with Us</span>
        </Title>
        <Paragraph className="grey-text touch-description paragraph">
          Feel free to reach out! Whether you have inquiries, feedback, we're
          here for you. Your thoughts matter, and we look forward to hearing
          from you!
        </Paragraph>
        <Form
          layout="vertical"
          form={form}
          initialValues={
            {
              // layout: formLayout,
            }
          }
        >
          <Form.Item label="Full Name">
            <Input placeholder="Enter your full name" />
          </Form.Item>
          <Form.Item label="Contact number">
            <Input placeholder="Enter your contact number" />
          </Form.Item>
          <Form.Item>
            <Button className="contained-primary fullwidth">
              Submit details
            </Button>
          </Form.Item>
        </Form>
      </div>
      <img
        src={touchImage}
        // height="77px"
        alt="touch"
        className="touch-image"
      />
      <img
        src={crossDesign}
        height="77px"
        alt="cross-design"
        className="touch-design-2"
      />
    </div>
  );
};

export default GetInTouch;
