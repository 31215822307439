import React from "react";
import { Button, Card, Grid } from "antd";
import Title from "antd/es/typography/Title";
import star from "../../../../Assets/Images/star.svg";
import locationIcon from "../../../../Assets/Images/location.svg";
import dateIcon from "../../../../Assets/Images/calendar.svg";
import { Link } from "react-router-dom";

const RecommendationsCard = ({ recommendationData }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const { image, name, title, price, rating, location, startDate } =
    recommendationData;
  return (
    <Card
      hoverablec
      style={{
        width: "100%",
        boxShadow: "2px 5px 24px 0px #0000000F",
      }}
      styles={{
        body: {
          paddingTop: "10px",
        },
      }}
      cover={
        <img
          alt="recommendation"
          src={image}
          className="recommendation-card-image"
        />
      }
    >
      <div className="car-body-header">
        <Title level={3} className="recommendation-title">
          {title}
        </Title>
        <div className="rating-component">
          <img src={star} alt="rating" width="24px" />
          <Title level={3} className="title-margin-custom">
            {rating}
          </Title>
        </div>
      </div>
      <div className="location-component d-flex align-center ">
        <img src={locationIcon} alt="location" />
        <Title level={5} className="title-margin-custom grey-text">
          {location}
        </Title>
      </div>
      <div className="date-component d-flex align-center ">
        <img src={dateIcon} alt="date" />
        <Title level={5} className="title-margin-custom grey-text">
          {startDate}
        </Title>
      </div>
      <div className="card-footer d-flex justify-between align-center">
        <div className="price-container d-flex align-center">
          <Title level={3} className="text-black title-margin-custom price">
            {" "}
            {price}/-
          </Title>
          <Title level={5} className="title-margin-custom grey-text">
            {" "}
            Onwards
          </Title>
        </div>
        <Link to={`/view-package/${name}`} preventScrollReset={true}>
          <Button variant="outlined" size="large" className="outlined-primary">
            View {!xs && "Details"}
          </Button>
        </Link>
      </div>
    </Card>
  );
};

export default RecommendationsCard;
