import { Card } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import { Rating } from "react-simple-star-rating";

const TravellerCard = ({ title, description, image, name, rating }) => {
  return (
    <Card className="traveller-card-container">
      <Title level={2} className="travellers-banner-text">
        “{title}”
      </Title>
      <Paragraph className="grey-text">"{description}"</Paragraph>
      <img src={image} alt="traveller" className="traveller-avatar" />
      <Title level={3} className="travellers-banner-text">
        {name}
      </Title>
      <Rating stop={5} initialValue={rating} readonly allowFraction />
    </Card>
  );
};

export default TravellerCard;
